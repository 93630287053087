import { gql } from "@apollo/client";

export const myAccountQuery = gql`
query Profile {
  currentUser {
    id
    firstName
    lastName
    email
    role
    accountDisabledAt
    pronouns
    createdAt
    profile {
      ... on Student {
        id
        attribution
        instagramHandle
        dob

        mobileNumber
        cohorts {
          name
          school {
            mailingAddress {
              state
              id
            }
            id
            name
          }
        }
      }
      ... on Teacher {
        id
        attribution
        mailingAddress {
          address1
          address2
          city
          state
          postCode
        }
        mobileNumber
        cohorts {
          name
          school {
            mailingAddress {
              state
              id
            }
            id
            name
          }
        }
      }
      ... on Facilitator {
        id
        instagramHandle
        website
        provisionalBio
        bio
        bioNeedsApproval
        attribution
        mailingAddress {
          address1
          address2
          city
          state
          postCode
        }
        mobileNumber
        cohorts {
          id
          name
          school {
            mailingAddress {
              state
              id
            }
            id
            name
          }
        }
      }
      ... on AppAdmin {
        id
        mobileNumber
        mailingAddress {
          address1
          address2
          city
          state
          postCode
        }
      }
      ... on Curator {
        id
        instagramHandle
        website
        provisionalBio
        bio
        bioNeedsApproval
        mailingAddress {
          address1
          address2
          city
          state
          postCode
        }
        mobileNumber
        cohorts {
          id
          name
          school {
            mailingAddress {
              state
              id
            }
            id
            name
          }
        }
      }
    }
  }
}
`