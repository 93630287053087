import moment from 'moment-timezone'

class DateUtils {
  static createMoment(date = null) {
    const defaultTimezone = 'America/Los_Angeles' // PDT timezone
    if (date) {
      if (moment.isMoment(date)) {
        return date.clone().tz(defaultTimezone)
      }
      return moment.tz(date, defaultTimezone)
    } else {
      return moment().tz(defaultTimezone)
    }
  }

  static formatDateString(d) {
    if (!d) return

    if (!d.getTime) d = new Date(d)
    return moment(d).format('YYYY-MM-DD')
  }

  static appendEndTimeToDate(date) {
    return moment(
      this.createMoment(Date)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .toString()
    )
  }

  static ensureValidDate(date) {
    if ((date instanceof Date || moment.isMoment(date)) && !isNaN(date)) {
      return date
    }
    if (typeof date === 'string' || typeof date === 'number') {
      const parsedDate = new Date(date)
      if (!isNaN(parsedDate)) {
        return parsedDate
      }
    }
    return null
  }

  static pendingTimeToEndDate(date) {
    const dateTime = moment.isMoment(date) ? date : this.createMoment(date)
    const currentMomentInTimezone = moment(this.createMoment().toString())
    const targetMomentInTimezone = this.createMoment(dateTime.format('YYYY-MM-DD HH:mm:ss'))

    return targetMomentInTimezone.from(currentMomentInTimezone, true)
  }

  static isDatePassed(date) {
    const dateTime = this.createMoment(date).hours(23).minutes(59).seconds(59)
    return moment(this.createMoment().toString()).isAfter(dateTime)
  }
}

export default DateUtils
