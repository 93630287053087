/* eslint-disable no-useless-escape */
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Grid, Typography } from '@mui/material'
import libphonenumber from 'google-libphonenumber'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { validationText } from '../../content/validationText.js'
import { USER_UPDATE_MUTATION } from '../../queries/userDetailsQueries.js'
import DateUtils from '../../utils/DateUtils.js'
import statesList from '../../utils/USAStates.js'
import Button from '../elements/Button.js'
import Cancel from '../elements/Cancel.js'
import DatePicker from '../elements/DatePicker'
import FormField from '../elements/FormField.js'
import FormSelect from '../elements/FormSelect.js'
import ChangeUserModal from '../molecules/ChangeUserModal.js'
import AttributionInfo from './AccountDetails/AttributionInfo.js'
import ProgramInfo from './AccountDetails/ProgramInfo.js'
import SecurityInfo from './AccountDetails/SecurityInfo.js'

const AccountDetails = props => {
  const [firstName, setFirstName] = useState(props.user.firstName)
  const [lastName, setLastName] = useState(props.user.lastName)
  const [pronouns] = useState(props.user.pronouns)
  const [email] = useState(props.user.email || null)
  // const [attribution, setAttribution] = useState(props.user.profile.attribution)
  // const [instagramHandle, setInstagramHandle] = useState(props.user.profile.instagramHandle)
  const [website, setWebsite] = useState(props.user.profile.website)
  const [dob, setDob] = useState(props?.user?.profile?.dob)
  const [mobileNumber, setMobileNumber] = useState(props.user.profile.mobileNumber)
  const [address1, setAddress1] = useState(_.get(props.user.profile, 'mailingAddress.address1'))
  const [address2, setAddress2] = useState(_.get(props.user.profile, 'mailingAddress.address2'))
  const [postCode, setPostCode] = useState(_.get(props.user.profile, 'mailingAddress.postCode'))
  const [city, setCity] = useState(_.get(props.user.profile, 'mailingAddress.city'))
  const [state, setState] = useState(_.get(props.user.profile, 'mailingAddress.state') || 'AL')
  // const [bio, setBio] = useState(props?.user?.profile?.provisionalBio || props?.user?.profile?.bio)
  const [bioApproved] = useState(
    props.user.profile.bio &&
      props.user.profile.bio !== '' &&
      props.user.profile.bio === props.user.profile.provisionalBio
  )
  // const [modalShow, setModalShow] = useState(false)
  // const [submitButtonClicked, setSubmitButtonClicked] = useState(
  //   props.user.profile.provisionalBio ? true : false
  // )
  // const [saveChangesClicked, setSaveChangesClicked] = useState(false)
  // const [approveBioButton, setApproveBioButton] = useState(false)
  const [modalShowConfirm, setModalShowConfirm] = useState(false)
  // const [startDate, setStartDate] = useState(new Date())
  const [bioErrors, setBioErrors] = useState([])
  const [accountInformationErrors, setAccountInformationErrors] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)

  const [updateUser] = useMutation(USER_UPDATE_MUTATION, {
    variables: {
      id: props?.userId || null,
      email: email,
      firstName: firstName,
      lastName: lastName,
      pronouns: pronouns,
      website: website,
      dob: DateUtils.formatDateString(dob),
      //bio: bio,
      bioApproved: bioApproved,
      mobileNumber: mobileNumber,
      address1: address1,
      address2: address2 || null,
      postCode: postCode,
      city: city,
      state: state,
      submitBioForApproval: false
    },
    async onCompleted() {
      await props?.fetchUserDetails?.()
      props.showToast('success', validationText.user.success.update)
      setIsProcessing(false)
    }
    // refetchQueries: [props.isAccountDetail ? `Profile` : `UserDetails`]
  })

  const initialState = sectionName => {
    if (sectionName === 'required') {
      setFirstName(props.user.firstName)
      setLastName(props.user.lastName)
      setDob(props?.user?.profile?.dob)
      setMobileNumber(props.user.profile.mobileNumber)
      setAddress1(_.get(props.user.profile, 'mailingAddress.address1'))
      setAddress2(_.get(props.user.profile, 'mailingAddress.address2'))
      setPostCode(_.get(props.user.profile, 'mailingAddress.postCode'))
      setCity(_.get(props.user.profile, 'mailingAddress.city'))
      setState(_.get(props.user.profile, 'mailingAddress.state') || 'AL')
      setAccountInformationErrors({
        firstName: null,
        lastName: null,
        dob: null,
        mobileNumber: null,
        address1: null,
        postCode: null,
        city: null,
        state: null
      })
    } else if (sectionName === 'attribution') {
      // setAttribution(props.user.profile.attribution)
      // setInstagramHandle(props.user.profile.instagramHandle)
      // setBioErrors([])
    }
  }

  const cancelProfileUpdate = sectionName => {
    initialState(sectionName)
  }

  // const showBio = () => {
  //   return (
  //     props.userStore.role === 'facilitator' ||
  //     props.userStore.role === 'curator' ||
  //     (props.userStore.role === 'appadmin'
  //       ? props.user
  //         ? props.user.role === 'Curator' || props.user.role === 'Facilitator'
  //         : true
  //       : false)
  //   )
  // }

  // const handleChange = date => {
  //   setStartDate(date)
  // }

  const validateChange = e => {
    const { user, userStore } = props
    const { profile } = user
    const profile_role = profile.__typename
    const { role } = userStore
    const fieldName = e.target.name
    const value = e.target.value
    switch (fieldName) {
      case 'firstName':
        if (
          props.userStore.role === 'appadmin' &&
          props.userStore.id !== profile.id &&
          (value === '' || value.trim().length === 0)
        ) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            firstName: validationText.user.error.firstName
          })
        } else if (value === '' || value.trim().length === 0) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            firstName: validationText.user.error.ownFirstName
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            firstName: null
          })
        }
        break
      case 'lastName':
        if (
          props.userStore.role === 'appadmin' &&
          props.userStore.id !== profile.id &&
          (value === '' || value.trim().length === 0)
        ) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            lastName: validationText.user.error.lastName
          })
        } else if (value === '' || value.trim().length === 0) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            lastName: validationText.user.error.ownLastName
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            lastName: null
          })
        }
        break
      case 'dob':
        let age = moment()
          .endOf('year')
          .diff(value, 'years')
        if (age < 13) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            dob: validationText.user.error.dob
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            dob: null
          })
        }
        break
      case 'mobileNumber':
        try {
          if (value.length === 0 && profile_role !== 'Student') {
            setAccountInformationErrors({
              ...accountInformationErrors,
              mobileNumber: validationText.user.error.mobileNumber
            })
          } else if (value.length === 0 && profile_role === 'Student') {
            setAccountInformationErrors({
              ...accountInformationErrors,
              mobileNumber: null
            })
          } else {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
            const number = phoneUtil.parse(value, 'US')

            if (value.length === 10 && phoneUtil.isValidNumber(number)) {
              setAccountInformationErrors({
                ...accountInformationErrors,
                mobileNumber: null
              })
            } else if (value.length === 0 && (profile_role === 'Student' || role === 'student')) {
              setAccountInformationErrors({
                ...accountInformationErrors,
                mobileNumber: null
              })
            } else {
              setAccountInformationErrors({
                ...accountInformationErrors,
                mobileNumber: validationText.user.error.validMobileNumber
              })
            }
          }
        } catch (error) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            mobileNumber: validationText.user.error.validMobileNumber
          })
        }
        break
      case 'address1':
        if (value === '') {
          setAccountInformationErrors({
            ...accountInformationErrors,
            address1: validationText.user.error.address1
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            address1: null
          })
        }
        break
      case 'postCode':
        if (value === '') {
          setAccountInformationErrors({
            ...accountInformationErrors,
            postCode: validationText.user.error.postCode
          })
        } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
          setAccountInformationErrors({
            ...accountInformationErrors,
            postCode: validationText.user.error.validPostCode
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            postCode: null
          })
        }
        break
      case 'city':
        if (value === '') {
          setAccountInformationErrors({
            ...accountInformationErrors,
            city: validationText.user.error.city
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            city: null
          })
        }
        break
      case 'state':
        if (value === '') {
          setAccountInformationErrors({
            ...accountInformationErrors,
            state: validationText.user.error.state
          })
        } else {
          setAccountInformationErrors({
            ...accountInformationErrors,
            state: null
          })
        }
        break
      case 'website':
        let regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

        if (!regexp.test(value)) {
          setBioErrors({
            ...bioErrors,
            website: validationText.user.error.website
          })
        } else {
          setBioErrors({
            ...bioErrors,
            website: null
          })
        }
        break
      case 'instagramHandle':
        let instaRegex = /@(?:[\w][\.]{0,1})*[\w]/
        if (!instaRegex.test(value)) {
          setBioErrors({
            ...bioErrors,
            instagramHandle: validationText.user.error.instagramHandle
          })
        } else {
          setBioErrors({
            ...bioErrors,
            instagramHandle: null
          })
        }
        break
      default:
        return
    }
  }

  const handleFormChange = e => {
    let checkValue = null
    if (_.includes(['text', 'number'], e.target.type)) {
      checkValue = e.target.value ? e.target.value : null
    } else {
      checkValue = e.target.value ? e.target.value : e.target.checked
    }
    // setSaveChangesClicked(true)
    // setSubmitButtonClicked(true)
    const fieldName = e.target.name
    switch (fieldName) {
      case 'firstName':
        setFirstName(checkValue)
        break
      case 'lastName':
        setLastName(checkValue)
        break
      case 'dob':
        setDob(checkValue)
        break
      case 'mobileNumber':
        setMobileNumber(checkValue)
        break
      case 'address1':
        setAddress1(checkValue)
        break
      case 'address2':
        setAddress2(checkValue)
        break
      case 'postCode':
        setPostCode(checkValue)
        break
      case 'city':
        setCity(checkValue)
        break
      case 'state':
        setState(checkValue)
        break
      case 'website':
        setWebsite(checkValue)
        break
      case 'instagramHandle':
        // setInstagramHandle(checkValue)
        break
      default:
        break
    }

    validateChange(e)
  }

  const modalClose = toastMessage => {
    setModalShowConfirm(false)
    if (toastMessage) {
      props?.showToast?.('success', toastMessage)
    }
  }

  const handleSubmit = () => {
    setIsProcessing(true)
    setAccountInformationErrors([])
    setBioErrors([])
    updateUser()
  }

  const { user, userStore } = props
  const { profile } = user
  const role = profile.__typename

  const isSubmitDisabled = () => {
    let isDisable =
      firstName === props.user.firstName &&
      lastName === props.user.lastName &&
      mobileNumber === props.user.profile.mobileNumber &&
      dob === props.user.profile.dob

    if (role !== 'Student') {
      isDisable =
        isDisable &&
        (address1 === _.get(props.user.profile, 'mailingAddress.address1') &&
          address2 === _.get(props.user.profile, 'mailingAddress.address2') &&
          postCode === _.get(props.user.profile, 'mailingAddress.postCode') &&
          city === _.get(props.user.profile, 'mailingAddress.city') &&
          state === _.get(props.user.profile, 'mailingAddress.state'))
    }

    return (
      isProcessing ||
      (isDisable || Object.values(accountInformationErrors).filter(val => val !== null).length)
    )
  }

  return (
    <Box
      className='container__body'
      sx={{
        pt: { xs: 5, sm: 5, md: 6, lg: 10 },
        pb: { xs: 0, sm: 0, md: 0, lg: 0 },
        px: { xs: 4, md: 6 }
      }}
    >
      <Box sx={{ mb: { xs: 7, sm: 6, md: 10, lg: 10 } }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant='h2' className='semi-strong'>
            Required Information
          </Typography>
        </Box>
        <Box>
          {/* <form className='my-account__form-container' noValidate autoComplete='on'> */}
          <Box>
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 3, md: 3 }}
              rowSpacing={{ xs: 5, sm: 0, md: 0 }}
            >
              <Grid item md={4} sm={6} xs={12}>
                <FormField
                  formFieldLabel='First Name'
                  formFieldVariant='filled'
                  formFieldClasses={{
                    root: 'text-field__outline'
                  }}
                  formFieldType='first-name'
                  formFieldName='firstName'
                  formFieldAutoComplete='given-name'
                  formFieldValue={firstName || ''}
                  onChange={handleFormChange}
                  formFieldError={accountInformationErrors?.firstName || null}
                  className='custom-input-bg'
                />
              </Grid>
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
                sx={{
                  pt: {
                    xs: accountInformationErrors?.firstName ? '10.5px !important' : 5,
                    sm: '0px !important'
                  }
                }}
              >
                {' '}
                <FormField
                  formFieldLabel='Last Name'
                  formFieldVariant='filled'
                  formFieldClasses={{
                    root: 'text-field__outline'
                  }}
                  formFieldType='lst-name'
                  formFieldName='lastName'
                  formFieldAutoComplete='given-name'
                  formFieldValue={lastName || ''}
                  onChange={handleFormChange}
                  formFieldError={accountInformationErrors?.lastName || null}
                  className='custom-input-bg'
                />
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={{ xs: 0, sm: 3, md: 3 }}
              rowSpacing={{ xs: 5, sm: 5, md: 5 }}
              sx={{
                pt: {
                  xs:
                    accountInformationErrors?.firstName || accountInformationErrors?.lastName
                      ? '10.5px !important'
                      : 5
                }
              }}
            >
              <Grid item md={4} sm={6} xs={12}>
                <FormField
                  formFieldLabel='Email'
                  formFieldVariant='filled'
                  formFieldType='email'
                  formFieldName='email'
                  disabled={true}
                  formFieldAutoComplete='email'
                  formFieldValue={email || ''}
                  onChange={handleFormChange}
                  formFieldError={accountInformationErrors?.email || null}
                  className='custom-input-bg'
                />
                <Box sx={{ mt: 0.25 }}>
                  <Typography variant='paragraph' className='note-style'>
                    To edit {props.user.id === props.userStore.id ? 'your' : ' the user’s'} email
                    address, please contact the{' '}
                    {props.userStore.role === 'appadmin' ? (
                      'web app engineering team'
                    ) : (
                      <>
                        <i>wrkxfmly</i> administrator
                      </>
                    )}
                    .
                  </Typography>
                </Box>
              </Grid>
              {_.includes(['Teacher', 'Facilitator', 'Curator', 'AppAdmin'], role) ? (
                <Grid item md={4} sm={6} xs={12}>
                  <FormField
                    formFieldId='outlined-number'
                    formFieldLabel='Mobile Number'
                    formFieldVariant='filled'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='number'
                    formFieldName='mobileNumber'
                    formFieldAutoComplete='tel'
                    formFieldValue={mobileNumber || ''}
                    onChange={handleFormChange}
                    formFieldError={accountInformationErrors?.mobileNumber || null}
                    className='custom-input-bg'
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
          <Box sx={{ mt: { xs: 7, sm: 6, md: 6 } }}>
            {_.includes(['Student'], role) ? (
              <Box>
                <Box>
                  <Typography variant='h2' className='semi-strong'>
                    Optional Information
                  </Typography>
                  <Grid container>
                    <Grid item md={7} sm={12} xs={12}>
                      <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
                        We ask for this information so that we can show{' '}
                        {_.includes(['appadmin'], props.userStore.role) ? 'the student' : 'you'}{' '}
                        age-appropriate forms and send notifications by text message.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 3, md: 3 }}
                    rowSpacing={{ xs: 5, sm: 5, md: 5 }}
                  >
                    <Grid item md={4} sm={6} xs={12}>
                      <DatePicker
                        onChange={date =>
                          handleFormChange({
                            target: {
                              value: date,
                              name: 'dob'
                            }
                          })
                        }
                        value={dob || ''}
                        helperText={accountInformationErrors?.dob}
                        error={accountInformationErrors?.dob}
                        label='Date of Birth'
                        className='custom-input-bg'
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FormField
                        formFieldId='outlined-number'
                        formFieldLabel='Mobile Number'
                        formFieldVariant='filled'
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldType='number'
                        formFieldName='mobileNumber'
                        formFieldAutoComplete='tel'
                        formFieldValue={mobileNumber || ''}
                        onChange={handleFormChange}
                        formFieldError={accountInformationErrors?.mobileNumber || null}
                        className='custom-input-bg'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box>
                <Box>
                  <Typography variant='h2' className='semi-strong'>
                    Preferred Mailing Address
                  </Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid
                    container
                    columnSpacing={{ xs: 0, sm: 3, md: 3 }}
                    rowSpacing={{ xs: 5, md: 0 }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={8}
                      sx={{
                        mb: {
                          xs: accountInformationErrors?.address1 ? -3.5 : 0
                        }
                      }}
                    >
                      <FormField
                        formFieldId='address-1'
                        formFieldVariant='filled'
                        formFieldLabel='Address Line 1'
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldType='text'
                        formFieldName='address1'
                        formFieldAutoComplete='address-line1'
                        formFieldValue={address1 || ''}
                        onChange={handleFormChange}
                        formFieldError={accountInformationErrors?.address1 || null}
                        className='custom-input-bg'
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={8}>
                      <FormField
                        formFieldId='address-2'
                        formFieldVariant='filled'
                        formFieldLabel='Address Line 2 (Optional)'
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldType='text'
                        formFieldName='address2'
                        formFieldAutoComplete='address-line2'
                        formFieldValue={address2 || ''}
                        onChange={handleFormChange}
                        formFieldError={accountInformationErrors?.address2 || null}
                        className='custom-input-bg'
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: { xs: accountInformationErrors?.address2 ? -3.5 : 5 }
                    }}
                  >
                    <Grid
                      container
                      columnSpacing={{ xs: 0, sm: 3, md: 3 }}
                      rowSpacing={{ xs: 5, md: 0 }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sm={8}
                        sx={{
                          mb: { xs: accountInformationErrors?.city ? -3.5 : 0 }
                        }}
                      >
                        <FormField
                          formFieldId='city'
                          formFieldVariant='filled'
                          formFieldLabel='City'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='city'
                          formFieldAutoComplete='address-level2'
                          formFieldValue={city || ''}
                          onChange={handleFormChange}
                          formFieldError={accountInformationErrors?.city || null}
                          className='custom-input-bg'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sm={4.01}
                        sx={{
                          mb: { xs: accountInformationErrors?.state ? -3.5 : 0 }
                        }}
                      >
                        <FormSelect
                          variant='filled'
                          inputProps={{
                            'aria-label': 'Without label'
                          }}
                          fullWidth={true}
                          value={state || statesList?.[0]?.value}
                          defaultValue={state || statesList?.[0]?.value}
                          options={statesList}
                          name='state'
                          label='State'
                          labelId='state-select-filled-label'
                          id='state-select-filled-label'
                          onChange={e => {
                            setState(e.target.value)
                            validateChange(e)
                          }}
                          className='custom-input-bg'
                          formFieldError={accountInformationErrors?.state || null}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        sm={4}
                        sx={{
                          mb: {
                            xs: accountInformationErrors?.postCode ? -3.5 : 0
                          }
                        }}
                      >
                        <FormField
                          formFieldId='zip-code'
                          formFieldVariant='filled'
                          formFieldLabel='Zip Code'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='number'
                          formFieldName='postCode'
                          formFieldAutoComplete='postal-code'
                          formFieldValue={postCode || ''}
                          onChange={handleFormChange}
                          formFieldError={accountInformationErrors?.postCode || null}
                          className='custom-input-bg'
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 5 }}>
            {_.includes(['Student', 'Teacher', 'Facilitator', 'Curator', 'AppAdmin'], role) &&
            !_.includes(['appadmin'], userStore.role) ? (
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'flex',
                    md: 'flex'
                  }
                }}
              >
                <Button
                  type='submit'
                  title={
                    isProcessing ? (
                      <span className='icon-text-span'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />{' '}
                        Saving...{' '}
                      </span>
                    ) : (
                      'Save Changes'
                    )
                  }
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled()}
                  sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
                />
                <Box
                  sx={{
                    ml: { xs: 0, sm: 2, md: 2 },
                    p: { xs: 3, sm: 2, md: 2 },
                    textAlign: { xs: 'center', sm: 'left', md: 'left' }
                  }}
                >
                  <Cancel
                    onClick={() => {
                      cancelProfileUpdate('required')
                    }}
                    showToast={props.showToast}
                  />
                </Box>
              </Box>
            ) : _.includes(['appadmin'], userStore.role) ? (
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'flex',
                    md: 'flex'
                  }
                }}
              >
                <Button
                  type='submit'
                  title='Save Changes'
                  disabled={isSubmitDisabled()}
                  onClick={e => {
                    e.preventDefault()
                    setModalShowConfirm(true)
                  }}
                  sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
                />
                <Box
                  sx={{
                    ml: { xs: 0, sm: 2, md: 2 },
                    p: { xs: 3, sm: 2, md: 2 },
                    textAlign: { xs: 'center', sm: 'left', md: 'left' }
                  }}
                >
                  <Cancel
                    onClick={() => {
                      cancelProfileUpdate('required')
                    }}
                    showToast={props.showToast}
                  />
                </Box>

                <ChangeUserModal
                  show={modalShowConfirm}
                  onHide={modalClose}
                  onSuccessCallback={modalClose}
                  handelSubmit={handleSubmit}
                />
              </Box>
            ) : null}
          </Box>
          {/* </form> */}
        </Box>
      </Box>

      {/* As per Kate's comment on notion doc https://www.notion.so/workingassumptions/User-Detail-Responsive-Improvements-45d624833686487ca9cb6e580b114adb */}

      {/* {this.showBio() ? (
                <Box>
                  <Box>
                    <Typography variant='h2' component='h2'>
                      Biography
                    </Typography>
                  </Box>
                  <Box>
                    <Box>
                      {this.props.userStore.role === 'facilitator' ||
                      this.props.userStore.role === 'curator' ? (
                        <Box>
                          <Typography variant='paragraph' component='p'>
                            Please write a short biography introducing your work and
                            background. This will be shared with teachers and students in
                            your classes.
                          </Typography>
                        </Box>
                      ) : null}

                      <Grid container>
                        <Grid item md={8} sm={12} xs={12}>
                          <FormField
                            formFieldId='biography'
                            formFieldVariant='filled'
                            formFieldLabel='Biography'
                            formFieldPlaceholder='Start writing your biography here. How were you introduced to photography? How does work and family overlap in your life?'
                            formFieldType='text'
                            formFieldName='bio'
                            formFieldMargin='auto'
                            formFieldDefaultValue={this.state.bio}
                            onChange={handleFormChange}
                            multiline={true}
                            className='custom-input-bg'
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      {this.props.userStore.role === 'facilitator' ||
                      this.props.userStore.role === 'curator' ? (
                        <Box>
                          <Typography variant='paragraph' component='p'>
                            Please link to your professional portfolio website or
                            Instagram account. Do not include links if they contain
                            photographs featuring nudity or adult themes. If in doubt,
                            please contact Working Assumptions for guidance.
                          </Typography>
                        </Box>
                      ) : null}
                      <Grid container spacing={3}>
                        <Grid item md={4} sm={12} xs={12}>
                          <FormField
                            formFieldId='website'
                            formFieldVariant='filled'
                            formFieldLabel='Website'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='website'
                            onChange={handleFormChange}
                            formFieldDefaultValue={this.state.website}
                            formFieldError={this.state.bioErrors.website || null}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                          <FormField
                            formFieldId='instagram-handle'
                            formFieldVariant='filled'
                            formFieldLabel='Instagram'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='instagramHandle'
                            onChange={handleFormChange}
                            formFieldDefaultValue={this.state.instagramHandle}
                            formFieldError={this.state.bioErrors.instagramHandle || null}
                            formFieldInputPropsStart={
                              <InputAdornment position='start'>@</InputAdornment>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      {this.props.userStore.role === 'facilitator' ||
                      this.props.userStore.role === 'curator' ? (
                        <Box>
                          <Typography variant='paragraph' component='p'>
                            Once you finish your biography, please submit it to a{' '}
                            <i>wrkxfmly</i> administrator for approval. Changes will not
                            appear to other users until it has been approved.
                          </Typography>
                        </Box>
                      ) : null}
                      {this.props.userStore.role === 'appadmin' ? (
                        <div className='my-account__approve-bio'>
                          <FormCheckbox
                            name='bioApproved'
                            checked={
                              !this.state.saveChangesClicked && this.state.bioApproved
                            }
                            label='Approve and publish biography'
                            onChange={handleFormChange}
                          />
                        </div>
                      ) : (
                        <Fragment>
                          <Button
                            // variant='primary'
                            type='submit'
                            title='Submit for Approval'
                            onClick={e => {
                              handleSubmit(
                                e,
                                onMutate,
                                true,
                                this.state.saveChangesClicked
                              )
                            }}
                            disabled={
                              this.state.bio && this.state.bio.trim().length >= 1
                                ? this.state.submitButtonClicked
                                  ? !(
                                      this.state.bio !==
                                        this.props.user.profile.provisionalBio ||
                                      this.state.website !==
                                        this.props.user.profile.website
                                    ) &&
                                    Object.values(this.state.bioErrors).filter(
                                      val => val !== null
                                    ).length === 0
                                  : false
                                : true
                            }
                          />
                          &nbsp;&nbsp;&nbsp;
                        </Fragment>
                      )}

                      <Button
                        // variant='outline-dark'
                        type='submit'
                        title='Save Changes'
                        onClick={e => {
                          handleSubmit(e, onMutate, this.state.submitButtonClicked, true)
                        }}
                        disabled={
                          this.state.saveChangesClicked
                            ? this.state.bioApproved !== this.props.bioApproved ||
                              this.state.bio !== this.props.bio ||
                              this.state.website !== this.props.website ||
                              (this.state.instagramHandle !==
                                this.props.instagramHandle &&
                                Object.values(this.state.bioErrors).filter(
                                  val => val !== null
                                ).length === 0)
                            : this.props.userStore.role === 'appadmin'
                            ? this.state.bioApproved
                              ? false
                              : true
                            : false
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              ) : null} */}
      <AttributionInfo
        user={user}
        showToast={props.showToast}
        fetchUserDetails={props.fetchUserDetails}
      />
      <Box>
        <SecurityInfo user={user} showToast={props.showToast} />
        <ProgramInfo user={user} />
      </Box>
    </Box>
  )
}

// class AccountDetails extends Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       modalShow: false,
//       submitButtonClicked: this.props.user.profile.provisionalBio ? true : false,
//       saveChangesClicked: false,
//       approveBioButton: false,
//       modalShowConfirm: false,
//       startDate: new Date(),
//       bioErrors: [],
//       accountInformationErrors: [],
//       email: this.props.user.email,
//       firstName: this.props.user.firstName,
//       lastName: this.props.user.lastName,
//       pronouns: this.props.user.pronouns,
//       attribution: this.props.user.profile.attribution,
//       instagramHandle: this.props.user.profile.instagramHandle,
//       website: this.props.user.profile.website,
//       bio: this.props.user.profile.provisionalBio
//         ? this.props.user.profile.provisionalBio
//         : this.props.user.profile.bio,
//       bioApproved:
//         this.props.user.profile.bio &&
//         this.props.user.profile.bio !== '' &&
//         this.props.user.profile.bio === this.props.user.profile.provisionalBio,
//       dob:
//         this.props.user && this.props.user.profile && this.props.user.profile.dob
//           ? new Date(this.props.user.profile.dob)
//           : '',
//       mobileNumber: this.props.user.profile.mobileNumber,
//       address1: _.get(this.props.user.profile, 'mailingAddress.address1'),
//       address2: _.get(this.props.user.profile, 'mailingAddress.address2'),
//       postCode: _.get(this.props.user.profile, 'mailingAddress.postCode'),
//       city: _.get(this.props.user.profile, 'mailingAddress.city'),
//       state: _.get(this.props.user.profile, 'mailingAddress.state') || 'AL'
//     }
//     this.modalClose = this.modalClose.bind(this)
//     this.handleChange = this.handleChange.bind(this)
//     this.handleFormChange = this.handleFormChange.bind(this)
//     this.handleSubmit = this.handleSubmit.bind(this)
//     this.toggleAttribution = this.toggleAttribution.bind(this)
//   }
//   cancelProfileUpdate = sectionName => {
//     if (sectionName === 'required') {
//       this.setState({
//         firstName: this.props.user.firstName,
//         lastName: this.props.user.lastName,
//         dob:
//           this.props.user && this.props.user.profile && this.props.user.profile.dob
//             ? new Date(this.props.user.profile.dob)
//             : '',
//         mobileNumber: this.props.user.profile.mobileNumber,
//         address1: _.get(this.props.user.profile, 'mailingAddress.address1'),
//         address2: _.get(this.props.user.profile, 'mailingAddress.address2'),
//         postCode: _.get(this.props.user.profile, 'mailingAddress.postCode'),
//         city: _.get(this.props.user.profile, 'mailingAddress.city'),
//         state: _.get(this.props.user.profile, 'mailingAddress.state') || 'AL',
//         accountInformationErrors: {
//           ...this.accountInformationErrors,
//           firstName: null,
//           lastName: null,
//           dob: null,
//           mobileNumber: null,
//           address1: null,
//           postCode: null,
//           city: null,
//           state: null
//         }
//       })
//     } else if (sectionName === 'attribution') {
//       this.setState({
//         attribution: this.props.user.profile.attribution,
//         instagramHandle: this.props.user.profile.instagramHandle,
//         bioErrors: []
//       })
//     }
//   }
//   showBio() {
//     return (
//       this.props.userStore.role === 'facilitator' ||
//       this.props.userStore.role === 'curator' ||
//       (this.props.userStore.role === 'appadmin'
//         ? this.props.user
//           ? this.props.user.role === 'Curator' || this.props.user.role === 'Facilitator'
//           : true
//         : false)
//     )
//   }
//   handleChange(date) {
//     this.setState({
//       startDate: date
//     })
//   }

//   validateChange(e) {
//     const { user, userStore } = this.props
//     const { profile } = user
//     const profile_role = profile.__typename
//     const { role } = userStore
//     const fieldName = e.target.name
//     const value = e.target.value
//     switch (fieldName) {
//       case 'firstName':
//         if (
//           this.props.userStore.role === 'appadmin' &&
//           this.props.userStore.id !== profile.id &&
//           (value === '' || value.trim().length === 0)
//         ) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               firstName: validationText.user.error.firstName
//             }
//           })
//         } else if (value === '' || value.trim().length === 0) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               firstName: validationText.user.error.ownFirstName
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, firstName: null }
//           })
//         }
//         break
//       case 'lastName':
//         if (
//           this.props.userStore.role === 'appadmin' &&
//           this.props.userStore.id !== profile.id &&
//           (value === '' || value.trim().length === 0)
//         ) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               lastName: validationText.user.error.lastName
//             }
//           })
//         } else if (value === '' || value.trim().length === 0) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               lastName: validationText.user.error.ownLastName
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, lastName: null }
//           })
//         }
//         break
//       case 'dob':
//         let age = moment()
//           .endOf('year')
//           .diff(value, 'years')
//         if (age < 13) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               dob: validationText.user.error.dob
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, dob: null }
//           })
//         }
//         break
//       case 'mobileNumber':
//         let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

//         try {
//           if (value.length === 0 && profile_role !== 'Student') {
//             this.setState({
//               accountInformationErrors: {
//                 ...this.state.accountInformationErrors,
//                 mobileNumber: validationText.user.error.mobileNumber
//               }
//             })
//           } else if (value.length === 0 && profile_role === 'Student') {
//             this.setState({
//               accountInformationErrors: {
//                 ...this.state.accountInformationErrors,
//                 mobileNumber: null
//               }
//             })
//           } else {
//             let number = phoneUtil.parse(value, 'US')

//             if (value.length === 10 && phoneUtil.isValidNumber(number)) {
//               this.setState({
//                 accountInformationErrors: {
//                   ...this.state.accountInformationErrors,
//                   mobileNumber: null
//                 }
//               })
//             } else if (value.length === 0 && (profile_role === 'Student' || role === 'student')) {
//               this.setState({
//                 accountInformationErrors: {
//                   ...this.state.accountInformationErrors,
//                   mobileNumber: null
//                 }
//               })
//             } else {
//               this.setState({
//                 accountInformationErrors: {
//                   ...this.state.accountInformationErrors,
//                   mobileNumber: validationText.user.error.validMobileNumber
//                 }
//               })
//             }
//           }
//         } catch (error) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               mobileNumber: validationText.user.error.validMobileNumber
//             }
//           })
//         }
//         break
//       case 'address1':
//         if (value === '') {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               address1: validationText.user.error.address1
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, address1: null }
//           })
//         }
//         break
//       case 'postCode':
//         if (value === '') {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               postCode: validationText.user.error.postCode
//             }
//           })
//         } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               postCode: validationText.user.error.validPostCode
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, postCode: null }
//           })
//         }
//         break
//       case 'city':
//         if (value === '') {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               city: validationText.user.error.city
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, city: null }
//           })
//         }
//         break
//       case 'state':
//         if (value === '') {
//           this.setState({
//             accountInformationErrors: {
//               ...this.state.accountInformationErrors,
//               state: validationText.user.error.state
//             }
//           })
//         } else {
//           this.setState({
//             accountInformationErrors: { ...this.state.accountInformationErrors, state: null }
//           })
//         }
//         break
//       case 'website':
//         let regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

//         if (!regexp.test(value)) {
//           this.setState({
//             bioErrors: { ...this.state.bioErrors, website: validationText.user.error.website }
//           })
//         } else {
//           this.setState({
//             bioErrors: { ...this.state.bioErrors, website: null }
//           })
//         }
//         break
//       case 'instagramHandle':
//         let instaRegex = /@(?:[\w][\.]{0,1})*[\w]/
//         if (!instaRegex.test(value)) {
//           this.setState({
//             bioErrors: {
//               ...this.state.bioErrors,
//               instagramHandle: validationText.user.error.instagramHandle
//             }
//           })
//         } else {
//           this.setState({
//             bioErrors: { ...this.state.bioErrors, instagramHandle: null }
//           })
//         }
//         break
//       default:
//         return
//     }
//   }

//   handleFormChange(e) {
//     let checkValue = ''
//     if (e.target.type == 'text') {
//       checkValue = e.target.value ? e.target.value : ''
//     } else {
//       checkValue = e.target.value ? e.target.value : e.target.checked
//     }
//     this.setState({
//       [e.target.name]: checkValue,
//       submitButtonClicked: false,
//       saveChangesClicked: false
//     })
//     this.validateChange(e)
//   }

//   modalClose(toastMessage) {
//     this.setState({ modalShow: false, modalShowConfirm: false })
//     if (toastMessage) {
//       this.props.showToast('success', toastMessage)
//     }
//   }
//   handleSubmit(e, onMutate, type, submitBioForApproval, saveChangesButton) {
//     e.preventDefault()
//     this.setState({
//       accountInformationErrors: [],
//       bioErrors: [],
//       submitButtonClicked: submitBioForApproval,
//       saveChangesClicked: saveChangesButton
//     })
//     onMutate({
//       variables: {
//         id: this.props.userId ? this.props.userId : null,
//         email: this.state.email,
//         firstName: this.state.firstName,
//         lastName: this.state.lastName,
//         pronouns: this.state.pronouns,
//         website: this.state.website,
//         dob: DateUtils.formatDateString(this.state.dob),
//         //bio: this.state.bio,
//         bioApproved: this.state.bioApproved,
//         mobileNumber: this.state.mobileNumber,
//         address1: this.state.address1,
//         address2: this.state.address2 ? this.state.address2 : null,
//         postCode: this.state.postCode,
//         city: this.state.city,
//         state: this.state.state,
//         submitBioForApproval: submitBioForApproval
//       },
//       refetchQueries: [this.props.isAccountDetail ? `Profile` : `UserDetails`]
//     })
//     this.props.userStore.setPhoneNumber(this.state.mobileNumber)
//   }

//   toggleAttribution(value) {
//     this.setState({ attribution: value })
//   }

//   render() {
//     const { user, userStore } = this.props
//     const { profile } = user
//     const role = profile.__typename
//     const { handleFormChange, handleSubmit, modalClose } = this
//     return (
//       <Mutation
//         mutation={USER_UPDATE_MUTATION}
//         onCompleted={() => {
//           this.props?.fetchUserDetails?.()
//           this.props.showToast('success', validationText.user.success.update)
//         }}
//         update={async () => {
//           // await this.props.client.writeFragment({
//           //   id: `${this.props.user.role}:${this.props.user.profile.id}`,
//           //   fragment: gql`
//           //     fragment UpdateBio on ${this.props.user.role} {
//           //       bio
//           //     }
//           //   `,
//           //   data: {
//           //     __typename: this.props.user.role,
//           //     bio: this.state.bio
//           //   }
//           // })
//         }}
//       >
//         {(onMutate, { data }) => {
//           return (
//             <Box
//               className='container__body'
//               sx={{
//                 pt: { xs: 5, sm: 5, md: 6, lg: 10 },
//                 pb: { xs: 0, sm: 0, md: 0, lg: 0 },
//                 px: { xs: 4, md: 6 }
//               }}
//             >
//               <Box sx={{ mb: { xs: 7, sm: 6, md: 10, lg: 10 } }}>
//                 <Box sx={{ mb: 3 }}>
//                   <Typography variant='h2' className='semi-strong'>
//                     Required Information
//                   </Typography>
//                 </Box>
//                 <Box>
//                   <form className='my-account__form-container' noValidate autoComplete='on'>
//                     <Box>
//                       <Grid
//                         container
//                         columnSpacing={{ xs: 0, sm: 3, md: 3 }}
//                         rowSpacing={{ xs: 5, sm: 0, md: 0 }}
//                       >
//                         <Grid item md={4} sm={6} xs={12}>
//                           <FormField
//                             formFieldLabel='First Name'
//                             formFieldVariant='filled'
//                             formFieldClasses={{
//                               root: 'text-field__outline'
//                             }}
//                             formFieldType='first-name'
//                             formFieldName='firstName'
//                             formFieldAutoComplete='given-name'
//                             formFieldValue={this.state.firstName || ''}
//                             onChange={handleFormChange}
//                             formFieldError={this.state.accountInformationErrors.firstName || null}
//                             className='custom-input-bg'
//                           />
//                         </Grid>
//                         <Grid
//                           item
//                           md={4}
//                           sm={6}
//                           xs={12}
//                           sx={{
//                             pt: {
//                               xs: this.state.accountInformationErrors.firstName
//                                 ? '10.5px !important'
//                                 : 5,
//                               sm: '0px !important'
//                             }
//                           }}
//                         >
//                           {' '}
//                           <FormField
//                             formFieldLabel='Last Name'
//                             formFieldVariant='filled'
//                             formFieldClasses={{
//                               root: 'text-field__outline'
//                             }}
//                             formFieldType='lst-name'
//                             formFieldName='lastName'
//                             formFieldAutoComplete='given-name'
//                             formFieldValue={this.state.lastName || ''}
//                             onChange={handleFormChange}
//                             formFieldError={this.state.accountInformationErrors.lastName || null}
//                             className='custom-input-bg'
//                           />
//                         </Grid>
//                       </Grid>
//                       <Grid
//                         container
//                         columnSpacing={{ xs: 0, sm: 3, md: 3 }}
//                         rowSpacing={{ xs: 5, sm: 5, md: 5 }}
//                         sx={{
//                           pt: {
//                             xs:
//                               this.state.accountInformationErrors.firstName ||
//                               this.state.accountInformationErrors.lastName
//                                 ? '10.5px !important'
//                                 : 5
//                           }
//                         }}
//                       >
//                         <Grid item md={4} sm={6} xs={12}>
//                           <FormField
//                             formFieldLabel='Email'
//                             formFieldVariant='filled'
//                             formFieldType='email'
//                             formFieldName='email'
//                             disabled={true}
//                             formFieldAutoComplete='email'
//                             formFieldValue={this.state.email || ''}
//                             onChange={handleFormChange}
//                             formFieldError={this.state.accountInformationErrors.email || null}
//                             className='custom-input-bg'
//                           />
//                           <Box sx={{ mt: 0.25 }}>
//                             <Typography variant='paragraph' className='note-style'>
//                               To edit{' '}
//                               {this.props.user.id === this.props.userStore.id
//                                 ? 'your'
//                                 : ' the user’s'}{' '}
//                               email address, please contact the{' '}
//                               {this.props.userStore.role === 'appadmin' ? (
//                                 'web app engineering team'
//                               ) : (
//                                 <>
//                                   <i>wrkxfmly</i> administrator
//                                 </>
//                               )}
//                               .
//                             </Typography>
//                           </Box>
//                         </Grid>
//                         {_.includes(['Teacher', 'Facilitator', 'Curator', 'AppAdmin'], role) ? (
//                           <Grid item md={4} sm={6} xs={12}>
//                             <FormField
//                               formFieldId='outlined-number'
//                               formFieldLabel='Mobile Number'
//                               formFieldVariant='filled'
//                               formFieldClasses={{
//                                 root: 'text-field__outline'
//                               }}
//                               formFieldType='number'
//                               formFieldName='mobileNumber'
//                               formFieldAutoComplete='tel'
//                               formFieldValue={this.state.mobileNumber || ''}
//                               onChange={handleFormChange}
//                               formFieldError={
//                                 this.state.accountInformationErrors.mobileNumber || null
//                               }
//                               className='custom-input-bg'
//                             />
//                           </Grid>
//                         ) : null}
//                       </Grid>
//                     </Box>
//                     <Box sx={{ mt: { xs: 7, sm: 6, md: 6 } }}>
//                       {_.includes(['Student'], role) ? (
//                         <Box>
//                           <Box>
//                             <Typography variant='h2' className='semi-strong'>
//                               Optional Information
//                             </Typography>
//                             <Grid container>
//                               <Grid item md={7} sm={12} xs={12}>
//                                 <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
//                                   We ask for this information so that we can show{' '}
//                                   {_.includes(['appadmin'], this.props.userStore.role)
//                                     ? 'the student'
//                                     : 'you'}{' '}
//                                   age-appropriate forms and send notifications by text message.
//                                 </Typography>
//                               </Grid>
//                             </Grid>
//                           </Box>
//                           <Box sx={{ mt: 3 }}>
//                             <Grid
//                               container
//                               columnSpacing={{ xs: 0, sm: 3, md: 3 }}
//                               rowSpacing={{ xs: 5, sm: 5, md: 5 }}
//                             >
//                               <Grid item md={4} sm={6} xs={12}>
//                                 <DatePicker
//                                   onChange={date =>
//                                     handleFormChange({
//                                       target: {
//                                         value: date,
//                                         name: 'dob'
//                                       }
//                                     })
//                                   }
//                                   value={this.state.dob || ''}
//                                   helperText={this.state.accountInformationErrors.dob}
//                                   error={this.state.accountInformationErrors.dob}
//                                   label='Date of Birth'
//                                   className='custom-input-bg'
//                                 />
//                               </Grid>
//                               <Grid item md={4} sm={6} xs={12}>
//                                 <FormField
//                                   formFieldId='outlined-number'
//                                   formFieldVariant='filled'
//                                   formFieldLabel='Mobile Number'
//                                   formFieldClasses={{
//                                     root: 'text-field__outline'
//                                   }}
//                                   formFieldType='number'
//                                   formFieldName='mobileNumber'
//                                   formFieldAutoComplete='tel'
//                                   formFieldValue={this.state.mobileNumber || ''}
//                                   onChange={handleFormChange}
//                                   formFieldError={
//                                     this.state.accountInformationErrors.mobileNumber || null
//                                   }
//                                   className='custom-input-bg'
//                                 />
//                               </Grid>
//                             </Grid>
//                           </Box>
//                         </Box>
//                       ) : (
//                         <Box>
//                           <Box>
//                             <Typography variant='h2' className='semi-strong'>
//                               Preferred Mailing Address
//                             </Typography>
//                           </Box>
//                           <Box sx={{ mt: 3 }}>
//                             <Grid
//                               container
//                               columnSpacing={{ xs: 0, sm: 3, md: 3 }}
//                               rowSpacing={{ xs: 5, md: 0 }}
//                             >
//                               <Grid
//                                 item
//                                 xs={12}
//                                 md={4}
//                                 sm={8}
//                                 sx={{
//                                   mb: {
//                                     xs: this.state.accountInformationErrors.address1 ? -3.5 : 0
//                                   }
//                                 }}
//                               >
//                                 <FormField
//                                   formFieldId='address-1'
//                                   formFieldVariant='filled'
//                                   formFieldLabel='Address Line 1'
//                                   formFieldClasses={{
//                                     root: 'text-field__outline'
//                                   }}
//                                   formFieldType='text'
//                                   formFieldName='address1'
//                                   formFieldAutoComplete='address-line1'
//                                   formFieldValue={this.state.address1 || ''}
//                                   onChange={handleFormChange}
//                                   formFieldError={
//                                     this.state.accountInformationErrors.address1 || null
//                                   }
//                                   className='custom-input-bg'
//                                 />
//                               </Grid>
//                               <Grid item xs={12} md={4} sm={8}>
//                                 <FormField
//                                   formFieldId='address-2'
//                                   formFieldVariant='filled'
//                                   formFieldLabel='Address Line 2 (Optional)'
//                                   formFieldClasses={{
//                                     root: 'text-field__outline'
//                                   }}
//                                   formFieldType='text'
//                                   formFieldName='address2'
//                                   formFieldAutoComplete='address-line2'
//                                   formFieldValue={this.state.address2 || ''}
//                                   onChange={handleFormChange}
//                                   formFieldError={
//                                     this.state.accountInformationErrors.address2 || null
//                                   }
//                                   className='custom-input-bg'
//                                 />
//                               </Grid>
//                             </Grid>

//                             <Box
//                               sx={{
//                                 mt: { xs: this.state.accountInformationErrors.address2 ? -3.5 : 5 }
//                               }}
//                             >
//                               <Grid
//                                 container
//                                 columnSpacing={{ xs: 0, sm: 3, md: 3 }}
//                                 rowSpacing={{ xs: 5, md: 0 }}
//                               >
//                                 <Grid
//                                   item
//                                   xs={12}
//                                   md={4}
//                                   sm={8}
//                                   sx={{
//                                     mb: { xs: this.state.accountInformationErrors.city ? -3.5 : 0 }
//                                   }}
//                                 >
//                                   <FormField
//                                     formFieldId='city'
//                                     formFieldVariant='filled'
//                                     formFieldLabel='City'
//                                     formFieldClasses={{
//                                       root: 'text-field__outline'
//                                     }}
//                                     formFieldType='text'
//                                     formFieldName='city'
//                                     formFieldAutoComplete='address-level2'
//                                     formFieldValue={this.state.city || ''}
//                                     onChange={handleFormChange}
//                                     formFieldError={
//                                       this.state.accountInformationErrors.city || null
//                                     }
//                                     className='custom-input-bg'
//                                   />
//                                 </Grid>
//                                 <Grid
//                                   item
//                                   xs={12}
//                                   md={2}
//                                   sm={4.01}
//                                   sx={{
//                                     mb: { xs: this.state.accountInformationErrors.state ? -3.5 : 0 }
//                                   }}
//                                 >
//                                   <FormSelect
//                                     variant='filled'
//                                     inputProps={{
//                                       'aria-label': 'Without label'
//                                     }}
//                                     fullWidth={true}
//                                     value={
//                                       this.state.state ? this.state.state : statesList[0].value
//                                     }
//                                     defaultValue={
//                                       this.state.state ? this.state.state : statesList[0].value
//                                     }
//                                     options={statesList}
//                                     name='state'
//                                     label='State'
//                                     labelId='state-select-filled-label'
//                                     id='state-select-filled-label'
//                                     onChange={e => {
//                                       this.setState({ state: e.target.value })
//                                       this.validateChange(e)
//                                     }}
//                                     className='custom-input-bg'
//                                     formFieldError={
//                                       this.state.accountInformationErrors.state || null
//                                     }
//                                   />
//                                 </Grid>
//                                 <Grid
//                                   item
//                                   xs={12}
//                                   md={2}
//                                   sm={4}
//                                   sx={{
//                                     mb: {
//                                       xs: this.state.accountInformationErrors.postCode ? -3.5 : 0
//                                     }
//                                   }}
//                                 >
//                                   <FormField
//                                     formFieldId='zip-code'
//                                     formFieldVariant='filled'
//                                     formFieldLabel='Zip Code'
//                                     formFieldClasses={{
//                                       root: 'text-field__outline'
//                                     }}
//                                     formFieldType='number'
//                                     formFieldName='postCode'
//                                     formFieldAutoComplete='postal-code'
//                                     formFieldValue={this.state.postCode || ''}
//                                     onChange={handleFormChange}
//                                     formFieldError={
//                                       this.state.accountInformationErrors.postCode || null
//                                     }
//                                     className='custom-input-bg'
//                                   />
//                                 </Grid>
//                               </Grid>
//                             </Box>
//                           </Box>
//                         </Box>
//                       )}
//                     </Box>
//                     <Box sx={{ mt: 5 }}>
//                       {_.includes(
//                         ['Student', 'Teacher', 'Facilitator', 'Curator', 'AppAdmin'],
//                         role
//                       ) && !_.includes(['appadmin'], userStore.role) ? (
//                         <Box
//                           sx={{
//                             display: {
//                               xs: 'block',
//                               sm: 'flex',
//                               md: 'flex'
//                             }
//                           }}
//                         >
//                           <Button
//                             type='submit'
//                             title='Save Changes'
//                             onClick={e => {
//                               handleSubmit(e, onMutate, 'profile')
//                             }}
//                             disabled={
//                               (this.state.firstName !== this.props.user.firstName ||
//                                 this.state.lastName !== this.props.user.lastName ||
//                                 this.state.email !== this.props.user.email ||
//                                 this.state.mobileNumber !== this.props.user.profile.mobileNumber ||
//                                 this.state.dob !== this.props.user.profile.dob ||
//                                 this.state.address1 !==
//                                   _.get(this.props.user.profile, 'mailingAddress.address1') ||
//                                 this.state.address2 !==
//                                   _.get(this.props.user.profile, 'mailingAddress.address2') ||
//                                 this.state.postCode !==
//                                   _.get(this.props.user.profile, 'mailingAddress.postCode') ||
//                                 this.state.city !==
//                                   _.get(this.props.user.profile, 'mailingAddress.city') ||
//                                 this.state.state !==
//                                   _.get(this.props.user.profile, 'mailingAddress.state')) &&
//                               Object.values(this.state.accountInformationErrors).filter(
//                                 val => val !== null
//                               ).length === 0
//                                 ? false
//                                 : true
//                             }
//                             sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
//                           />
//                           <Box
//                             sx={{
//                               ml: { xs: 0, sm: 2, md: 2 },
//                               p: { xs: 3, sm: 2, md: 2 },
//                               textAlign: { xs: 'center', sm: 'left', md: 'left' }
//                             }}
//                           >
//                             <Cancel
//                               onClick={() => {
//                                 this.cancelProfileUpdate('required')
//                               }}
//                               showToast={this.props.showToast}
//                             />
//                           </Box>
//                         </Box>
//                       ) : _.includes(['appadmin'], userStore.role) ? (
//                         <Box
//                           sx={{
//                             display: {
//                               xs: 'block',
//                               sm: 'flex',
//                               md: 'flex'
//                             }
//                           }}
//                         >
//                           <Button
//                             type='submit'
//                             title='Save Changes'
//                             disabled={
//                               Object.values(this.state.accountInformationErrors).filter(
//                                 val => val !== null
//                               ).length > 0
//                             }
//                             onClick={e => {
//                               e.preventDefault()
//                               this.setState({ modalShowConfirm: true })
//                             }}
//                             sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
//                           />
//                           <Box
//                             sx={{
//                               ml: { xs: 0, sm: 2, md: 2 },
//                               p: { xs: 3, sm: 2, md: 2 },
//                               textAlign: { xs: 'center', sm: 'left', md: 'left' }
//                             }}
//                           >
//                             <Cancel
//                               onClick={() => {
//                                 this.cancelProfileUpdate('required')
//                               }}
//                               showToast={this.props.showToast}
//                             />
//                           </Box>

//                           <ChangeUserModal
//                             show={this.state.modalShowConfirm}
//                             onHide={modalClose}
//                             onSuccessCallback={modalClose}
//                             handelSubmit={handleSubmit}
//                             mutate={onMutate}
//                           />
//                         </Box>
//                       ) : null}
//                     </Box>
//                   </form>
//                 </Box>
//               </Box>

//               {/* As per Kate's comment on notion doc https://www.notion.so/workingassumptions/User-Detail-Responsive-Improvements-45d624833686487ca9cb6e580b114adb */}

//               {/* {this.showBio() ? (
//                 <Box>
//                   <Box>
//                     <Typography variant='h2' component='h2'>
//                       Biography
//                     </Typography>
//                   </Box>
//                   <Box>
//                     <Box>
//                       {this.props.userStore.role === 'facilitator' ||
//                       this.props.userStore.role === 'curator' ? (
//                         <Box>
//                           <Typography variant='paragraph' component='p'>
//                             Please write a short biography introducing your work and
//                             background. This will be shared with teachers and students in
//                             your classes.
//                           </Typography>
//                         </Box>
//                       ) : null}

//                       <Grid container>
//                         <Grid item md={8} sm={12} xs={12}>
//                           <FormField
//                             formFieldId='biography'
//                             formFieldVariant='filled'
//                             formFieldLabel='Biography'
//                             formFieldPlaceholder='Start writing your biography here. How were you introduced to photography? How does work and family overlap in your life?'
//                             formFieldType='text'
//                             formFieldName='bio'
//                             formFieldMargin='auto'
//                             formFieldDefaultValue={this.state.bio}
//                             onChange={handleFormChange}
//                             multiline={true}
//                             className='custom-input-bg'
//                           />
//                         </Grid>
//                       </Grid>
//                     </Box>
//                     <Box>
//                       {this.props.userStore.role === 'facilitator' ||
//                       this.props.userStore.role === 'curator' ? (
//                         <Box>
//                           <Typography variant='paragraph' component='p'>
//                             Please link to your professional portfolio website or
//                             Instagram account. Do not include links if they contain
//                             photographs featuring nudity or adult themes. If in doubt,
//                             please contact Working Assumptions for guidance.
//                           </Typography>
//                         </Box>
//                       ) : null}
//                       <Grid container spacing={3}>
//                         <Grid item md={4} sm={12} xs={12}>
//                           <FormField
//                             formFieldId='website'
//                             formFieldVariant='filled'
//                             formFieldLabel='Website'
//                             formFieldClasses={{
//                               root: 'text-field__outline'
//                             }}
//                             formFieldType='text'
//                             formFieldName='website'
//                             onChange={handleFormChange}
//                             formFieldDefaultValue={this.state.website}
//                             formFieldError={this.state.bioErrors.website || null}
//                           />
//                         </Grid>
//                         <Grid item md={4} sm={12} xs={12}>
//                           <FormField
//                             formFieldId='instagram-handle'
//                             formFieldVariant='filled'
//                             formFieldLabel='Instagram'
//                             formFieldClasses={{
//                               root: 'text-field__outline'
//                             }}
//                             formFieldType='text'
//                             formFieldName='instagramHandle'
//                             onChange={handleFormChange}
//                             formFieldDefaultValue={this.state.instagramHandle}
//                             formFieldError={this.state.bioErrors.instagramHandle || null}
//                             formFieldInputPropsStart={
//                               <InputAdornment position='start'>@</InputAdornment>
//                             }
//                           />
//                         </Grid>
//                       </Grid>
//                     </Box>
//                     <Box>
//                       {this.props.userStore.role === 'facilitator' ||
//                       this.props.userStore.role === 'curator' ? (
//                         <Box>
//                           <Typography variant='paragraph' component='p'>
//                             Once you finish your biography, please submit it to a{' '}
//                             <i>wrkxfmly</i> administrator for approval. Changes will not
//                             appear to other users until it has been approved.
//                           </Typography>
//                         </Box>
//                       ) : null}
//                       {this.props.userStore.role === 'appadmin' ? (
//                         <div className='my-account__approve-bio'>
//                           <FormCheckbox
//                             name='bioApproved'
//                             checked={
//                               !this.state.saveChangesClicked && this.state.bioApproved
//                             }
//                             label='Approve and publish biography'
//                             onChange={handleFormChange}
//                           />
//                         </div>
//                       ) : (
//                         <Fragment>
//                           <Button
//                             // variant='primary'
//                             type='submit'
//                             title='Submit for Approval'
//                             onClick={e => {
//                               handleSubmit(
//                                 e,
//                                 onMutate,
//                                 true,
//                                 this.state.saveChangesClicked
//                               )
//                             }}
//                             disabled={
//                               this.state.bio && this.state.bio.trim().length >= 1
//                                 ? this.state.submitButtonClicked
//                                   ? !(
//                                       this.state.bio !==
//                                         this.props.user.profile.provisionalBio ||
//                                       this.state.website !==
//                                         this.props.user.profile.website
//                                     ) &&
//                                     Object.values(this.state.bioErrors).filter(
//                                       val => val !== null
//                                     ).length === 0
//                                   : false
//                                 : true
//                             }
//                           />
//                           &nbsp;&nbsp;&nbsp;
//                         </Fragment>
//                       )}

//                       <Button
//                         // variant='outline-dark'
//                         type='submit'
//                         title='Save Changes'
//                         onClick={e => {
//                           handleSubmit(e, onMutate, this.state.submitButtonClicked, true)
//                         }}
//                         disabled={
//                           this.state.saveChangesClicked
//                             ? this.state.bioApproved !== this.props.bioApproved ||
//                               this.state.bio !== this.props.bio ||
//                               this.state.website !== this.props.website ||
//                               (this.state.instagramHandle !==
//                                 this.props.instagramHandle &&
//                                 Object.values(this.state.bioErrors).filter(
//                                   val => val !== null
//                                 ).length === 0)
//                             : this.props.userStore.role === 'appadmin'
//                             ? this.state.bioApproved
//                               ? false
//                               : true
//                             : false
//                         }
//                       />
//                     </Box>
//                   </Box>
//                 </Box>
//               ) : null} */}
//               <AttributionInfo
//                 user={user}
//                 showToast={this.props.showToast}
//                 fetchUserDetails={this.props.fetchUserDetails}
//               />
//               <Box>
//                 <SecurityInfo user={user} showToast={this.props.showToast} />
//                 <ProgramInfo user={user} />
//               </Box>
//             </Box>
//           )
//         }}
//       </Mutation>
//     )
//   }
// }

export default inject('userStore')(observer(withApollo(AccountDetails)))
